import React, { useState } from "react";
import { useTheme } from "@/wireframes/theme/theme-provider";
import { ThemeToggle } from "@/wireframes/theme/theme-toggle";
// import { LangToggle } from "@/i18n/lang-toggle";
// import { useTranslation } from "react-i18next";
import KalibaLight from "@/assets/images/logo/logo-light.webp";
import KalibaDark from "@/assets/images/logo/logo-dark.webp";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const { t } = useTranslation();
  // const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  // const closeDropdown = () => {
  //   setDropdownOpen(false);
  // };

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (
  //     dropdownRef.current &&
  //     !dropdownRef.current.contains(event.target as Node)
  //   ) {
  //     closeDropdown();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const themeBgClass = theme === "dark" ? "bg-black" : "bg-white";

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-10 bg-background`}
      style={{ zIndex: 13, height: "4rem" }}
    >
      <div className="container-fluid mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between border-b border-gray-200">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <a href="#home">
                <img
                  className="h-8 w-auto"
                  src={theme === "dark" ? KalibaLight : KalibaDark}
                  alt="Kaliba Logo"
                />
              </a>
            </div>
          </div>
          <div className="flex items-center lg:hidden">
            <Button
              variant="outline"
              size="icon"
              type="button"
              onClick={toggleMenu}
              className={`inline-flex items-center justify-center rounded-md  p-2  focus:outline-none focus:border-red-200 hover:border-gray-200 hover:${themeBgClass}`}
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                ></path>
              </svg>
              <svg
                className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </Button>
            <div className="p-1 ml-2">
              <ThemeToggle />
            </div>
          </div>
          {/* Mobile menu */}
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } lg:hidden absolute top-16 w-full bg-accent text-foreground`}
          >
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/"
                className={`block px-3 py-2 rounded-md text-foreground font-medium hover:text-muted-foreground`}
              >
                Home
              </Link>
              <Link
                to="/about-us"
                className={`block px-3 py-2 rounded-md text-foreground font-medium hover:text-muted-foreground`}
              >
                About Us
              </Link>
              <Link
                to={`/#services`}
                className={`block px-3 py-2 rounded-md text-foreground font-medium hover:text-muted-foreground`}
              >
                Services
              </Link>
              <Link
                to="/contact-us"
                className={`block px-3 py-2 rounded-md text-foreground font-medium hover:text-muted-foreground`}
              >
                Contact Us
              </Link>
            </div>
          </div>
          {/* Desktop navigation */}
          <div className="hidden lg:flex lg:items-center lg:justify-end xl:justify-end">
            <div className="space-x-4">
              <Link
                to="/"
                className={`px-3 py-2 rounded-md text-foreground text-sm font-medium hover:text-muted-foreground`}
              >
                Home
              </Link>
              <Link
                to="/about-us"
                className={`px-3 py-2 rounded-md text-foreground text-sm font-medium hover:text-muted-foreground`}
              >
                About Us
              </Link>
              <Link
                to="/#services"
                className={`px-3 py-2 rounded-md text-foreground text-sm font-medium hover:text-muted-foreground`}
              >
                Services
              </Link>
              <Link
                to="/contact-us"
                className={`px-3 py-2 rounded-md text-foreground text-sm font-medium hover:text-muted-foreground`}
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="hidden lg:flex lg:items-center">
            <div className="p-1 mt-2">
              <ThemeToggle />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
